import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

export function clearAdditionalDishWhenTheQuantityIsZeroValidator(quantityDishControl: FormGroup): ValidationErrors | null {

    if (quantityDishControl.pristine) {

        return null;
    }

    const dishOfWeekFormCurrent = quantityDishControl?.parent?.parent?.parent as FormGroup;

    if (Boolean(dishOfWeekFormCurrent) === false) {

        return null;
    }

    const quantityDishOfDayControlCurrent = dishOfWeekFormCurrent.get('quantityDishOfDay') as FormControl;
    const quantityDishOfDayCurrent: number = quantityDishOfDayControlCurrent.value;

    if (quantityDishOfDayCurrent > 0) {

        return null;
    }

    const listAdditionalDishFormCurrent = dishOfWeekFormCurrent.get('listAdditionalDish') as FormArray;

    listAdditionalDishFormCurrent.clear();

    return null;

}
