import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { clearCustomDishWhenTheQuantityIsZeroValidator } from '@order-creator/commons/validators/clear-custom-dish-when-the-quantity-is-zero.validator';

export class CustomDishForm extends FormGroup {

    constructor() {

        const id = new FormControl();
        const amount = new FormControl();
        const listDecision = new FormArray([]);
        const quantity = new FormControl(0);
        const name = new FormControl();

        super(
            {
                id,
                amount,
                listDecision,
                quantity,
                name
            }
        );
    }
}

export class CustomDish {
    id: number;
    amount: number;
    listDecision?: boolean[];
    quantity?: number;
    name:string;
}
