import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DishOfWeek } from '@order-creator/commons/model/form/dish-of-week.form';
import { Dish } from '@order-creator/commons/model/form/dish.form';

export function calculateAmountAndQuantityOfDish(idControl: FormControl, amountControl: FormControl): ValidatorFn {

    if (Boolean(amountControl) === false && Boolean(idControl) === false) {
        return null;
    }

    return (quantityControl: FormControl): ValidationErrors | null => {

        if (quantityControl.pristine === true) {
            return null;
        }

        const quantity: number = quantityControl.value;
        const id: number = idControl.value;
        const amount: number = amountControl.value;

        if (Boolean(id) === false || Boolean(amount) === false) {
            return null;
        }

        const dishOfWeekControl = quantityControl.parent.parent.parent;

        if (Boolean(dishOfWeekControl) === false) {
            return null;
        }

        // console.count('count - amountOfDayValidator');


        const totalPartialCurrent = quantity * amount;
        const dishOfWeek: DishOfWeek = dishOfWeekControl.value;

        const amountDishOfDayControl = dishOfWeekControl.get('amountDishOfDay') as FormControl;
        const quantityDishOfDayControl = dishOfWeekControl.get('quantityDishOfDay') as FormControl;

        const listDish = dishOfWeek.listDish;
        const listDishExcludeCurrent = listDish.filter(dish => dish.id !== id);
        const listDishFiltered = listDishExcludeCurrent.filter(dish => Number(dish.quantity) > 0);

        const amountOfDay = getAmountOfDay(totalPartialCurrent, listDishFiltered);
        const quantityOfDay = getQuantityOfDay(quantity, listDishFiltered);

        amountDishOfDayControl.setValue(amountOfDay, { emitEvent: false });
        quantityDishOfDayControl.setValue(quantityOfDay, { emitEvent: false });

        return null;
    };
}

function getAmountOfDay(totalPartialCurrent: number, listDishFiltered: Dish[]): number {
    const listTotalParcial = listDishFiltered.map(dish => dish.amount * dish.quantity);
    const totalAmountWithCurrentExcluded = listTotalParcial.reduce((p, c) => p + c, 0);
    const totalAmount = totalAmountWithCurrentExcluded + totalPartialCurrent;

    return totalAmount;
}

function getQuantityOfDay(quantityCurrent: number, listDishFiltered: Dish[]): number {
    const listQuantityParcial = listDishFiltered.map(dish => dish.quantity);
    const totalQuantityWithCurrentExcluded = listQuantityParcial.reduce((p, c) => p + c, 0);
    const totalQuantity = totalQuantityWithCurrentExcluded + quantityCurrent;

    return totalQuantity;
}
