import { FormArray, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomDish, CustomDishForm } from '../model/form/dish-custom.form';

export function removeOfCustomDish(customDishForm: CustomDishForm): ValidatorFn {

    let quantityOfDishOld = 0;

    return (quantityOfDishControl: FormControl): ValidationErrors | null => {

        const quantityOfDish: number = quantityOfDishControl.value;

        if (quantityOfDishControl.pristine) {
            return null;
        }

        const DECREMENT: boolean = quantityOfDish < quantityOfDishOld ? true : false;

        if (DECREMENT) {
            removeItemAndUpdateQuantity(customDishForm);
        }

        quantityOfDishOld = quantityOfDish;

        return null;
    };
}

function removeItemAndUpdateQuantity(customDishForm: CustomDishForm): void {

    const customDish: CustomDish = customDishForm.value;
    const { quantity: quantityCurrent, listDecision } = customDish;

    if (listDecision.length === 0) {
        return;
    }

    const lastItem: number = listDecision.length - 1;
    const lastDecision: boolean = listDecision[lastItem];
    const valueToSubstract: number = lastDecision ? 1 : 0;

    // console.error('removeItemAndUpdateQuantity - lastDecision: ', lastDecision);
    // console.error('removeItemAndUpdateQuantity - valueToSubstract: ', valueToSubstract);

    const listDecisionControl = customDishForm.get('listDecision') as FormArray;
    const quantityControl = customDishForm.get('quantity') as FormControl;

    listDecisionControl.removeAt(lastItem);
    quantityControl.setValue(quantityCurrent - valueToSubstract);

}

