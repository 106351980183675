import { Injectable } from '@angular/core';
import { KEY } from './enumerables/key.enum';

@Injectable({
  providedIn: 'root'
})
export class OrderCreatorStateService {

  private vault: { [key: string]: any } = {};

  constructor() {
    this.registerInWindow();

  }

  public get<T>(key: KEY): T {

    if (Boolean(this.vault[key]) === false) {
     // console.log(`'${key}' not found in state!`);
    }

    return this.vault[key];
  }

  public set<T>(key: KEY, value: T): void {
    this.vault[key] = value;

  }

  public patch<T>(key: KEY, value: Partial<T>): void {
    const current = this.vault[key] || {};
    this.vault[key] = Object.assign<T, Partial<T>>(current, value);

  }

  public clear() {
    this.vault = {};

  }

  private registerInWindow(): void {
    (window as any).orderCreatorState = this;

  }

}
