import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { environment } from '@enviroments/environment';
import { AdditionalDishForm } from '@order-creator/commons/model/form/dish-additional.form';
import { DishOfWeek, DishOfWeekForm } from '@order-creator/commons/model/form/dish-of-week.form';
import { DishForm } from '@order-creator/commons/model/form/dish.form';
import { KEY } from '@order-creator/commons/services/state/enumerables/key.enum';
import { OrderCreatorStateService } from '@order-creator/commons/services/state/order-creator-state.service';
import * as orderCreatorStateMock from '../assets/mocks/order-creator-state.json';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  title = 'tappers-ui';

  constructor(
    private renderer: Renderer2,
    private orderCreatorStateService: OrderCreatorStateService,
    private router: Router
  ) {

    // filter a la finaliación de la navegación
    const navEndEvents$ = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd)
    )

    // suscripción al observable de finalización de navegación para enviarlo a google analytics
    navEndEvents$ .subscribe((event: NavigationEnd) => {
      gtag( 'config', 'UA-127808937-2', {
        'page_path': event.urlAfterRedirects
      });
    })

  }

  ngOnInit(): void {
    sessionStorage.clear();
    this.loadRecaptachaScript();
    this.restoreDataFromMockState();

  }

  private loadRecaptachaScript(): void {
    const script = this.renderer.createElement('script');
    script.defer = true;
    script.async = true;
    script.src = 'https://www.google.com/recaptcha/api.js';
    this.renderer.appendChild(document.body, script);

  }

  private restoreDataFromMockState(): void {
    if (environment.standalone === false) {
      return;

    }

    const state = (orderCreatorStateMock as any).default;

    const weeklyMenuForm = this.rebuildAndPatchValuesWeeklyMenuForm(state[KEY.WEEKLY_MENU_FORM]);
    const listOfDishOfWeekResponse = state[KEY.LIST_DISH_OF_WEEK_RESPONSE];
    const userInformation = state[KEY.USER_INFORMATION];

    this.orderCreatorStateService.set(KEY.WEEKLY_MENU_FORM, weeklyMenuForm);
    this.orderCreatorStateService.set(KEY.LIST_DISH_OF_WEEK_RESPONSE, listOfDishOfWeekResponse);
    this.orderCreatorStateService.set(KEY.USER_INFORMATION, userInformation);

  }

  private rebuildAndPatchValuesWeeklyMenuForm(listDishOfWeek: DishOfWeek[]): FormArray {

    const listDishOfWeekForm = listDishOfWeek.map<DishOfWeekForm>(dishOfWeek => {

      const dishOfWeekForm = new DishOfWeekForm();

      const listDishControl = dishOfWeekForm.get('listDish') as FormArray;
      const listAdditionalDishControl = dishOfWeekForm.get('listAdditionalDish') as FormArray;

      dishOfWeek.listDish?.forEach(dish => {
        const dishForm = new DishForm();
        const listDecisionControl = dishForm.get('customDish.listDecision') as FormArray;

        dish.customDish.listDecision.forEach(_ => {

          const decisionControl = new FormControl();
          listDecisionControl.push(decisionControl);
        });

        listDishControl.push(dishForm);

      });

      dishOfWeek.listAdditionalDish?.forEach(_ => {
        const additionalDishForm = new AdditionalDishForm();

        listAdditionalDishControl.push(additionalDishForm);

      });

      dishOfWeekForm.patchValue(dishOfWeek);

      return dishOfWeekForm;

    });

    return new FormArray([...listDishOfWeekForm]);

  }

}
