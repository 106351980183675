import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { calculateAmountAndQuantityOfAdditionalDish } from '@order-creator/steps/summary/components/aditionals-dishes/commons/validators/calculate-amount-and-quantity-of-additional-dish.validator';
import { AdditionalDish } from './dish-additional.form';
import { Dish } from './dish.form';

export class DishOfWeekForm extends FormGroup {

    constructor() {

        const dayId = new FormControl();
        const hasAdditionals = new FormControl();
        const listDish = new FormArray([]);
        const listAdditionalDish = new FormArray([]);
        const quantityDishOfDay = new FormControl(0);
        const quantityAdditionalDishOfDay = new FormControl(0);
        const amountDishOfDay = new FormControl(0);
        const amountAdditionalDishOfDay = new FormControl(0);

        // ADD VALIDATORS

        listAdditionalDish.setValidators(
            [
                calculateAmountAndQuantityOfAdditionalDish(quantityAdditionalDishOfDay, amountAdditionalDishOfDay)
            ]
        );

        super(
            {
                dayId,
                hasAdditionals,
                listDish,
                listAdditionalDish,
                quantityDishOfDay,
                quantityAdditionalDishOfDay,
                amountDishOfDay,
                amountAdditionalDishOfDay
            }
        );

    }

}

export interface DishOfWeek {
    dayId: number;
    hasAdditionals: boolean;
    listDish: Dish[];
    listAdditionalDish?: AdditionalDish[];
    quantityDishOfDay?: number;
    quantityAdditionalDishOfDay?: number;
    amountDishOfDay?: number;
    amountAdditionalDishOfDay?: number;
}
