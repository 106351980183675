import { FormArray, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AdditionalDish } from '@order-creator/commons/model/form/dish-additional.form';

export function calculateAmountAndQuantityOfAdditionalDish(
    quantityAdditionalDishOfDayControl: FormControl,
    amountAdditionalDishOfDayControl: FormControl
): ValidatorFn {

    return (listAdditionalDishControl: FormArray): ValidationErrors | null => {
        if (listAdditionalDishControl.touched === false) {
            return null;
        }

        const listAdditionalDish: AdditionalDish[] = listAdditionalDishControl.value;

        const quantityOfDay = getQuantityOfDay(listAdditionalDish);
        const amountOfDay = getAmountOfDay(listAdditionalDish);

        quantityAdditionalDishOfDayControl.setValue(quantityOfDay);
        amountAdditionalDishOfDayControl.setValue(amountOfDay);

        return null;
    };
}


function getAmountOfDay(listAdditionalDish: AdditionalDish[]): number {
    const listTotal = listAdditionalDish.map(additionalDish => additionalDish.amount * additionalDish.quantity);
    return listTotal.reduce((p, c) => p + c, 0);

}

function getQuantityOfDay(listAdditionalDish: AdditionalDish[]): number {
    const listQuantity = listAdditionalDish.map(additionalDish => additionalDish.quantity);
    return listQuantity.reduce((p, c) => p + c, 0);

}
