import { FormControl, FormGroup, Validators } from '@angular/forms';
import { clearAdditionalDishWhenTheQuantityIsZeroValidator } from '@order-creator/commons/validators/clear-additional-dish-when-is-empty.validator';
import { clearCustomDishWhenTheQuantityIsZeroValidator } from '@order-creator/commons/validators/clear-custom-dish-when-the-quantity-is-zero.validator';
import { removeOfCustomDish } from '@order-creator/commons/validators/remove-of-custom-dish.validator';
import { calculateAmountAndQuantityOfDish } from '@order-creator/steps/weekly-menu/validators/calculate-amount-and-quantity-of-dish.validator';
import { CustomDish, CustomDishForm } from './dish-custom.form';

export class DishForm extends FormGroup {

    constructor() {
        const id = new FormControl();
        const amount = new FormControl();
        const quantity = new FormControl(0);
        const hasCustomDish = new FormControl(false);
        const customDish = new CustomDishForm();

        // ADD VALIDATORS

        quantity.setValidators(Validators.compose(
            [
                removeOfCustomDish(customDish),
                calculateAmountAndQuantityOfDish(id, amount),
                clearAdditionalDishWhenTheQuantityIsZeroValidator,
                clearCustomDishWhenTheQuantityIsZeroValidator(customDish),
            ]
        ));

        super(
            {
                id,
                amount,
                quantity,
                hasCustomDish,
                customDish
            }
        );
    }
}

export interface Dish {
    id: number;
    amount: number;
    quantity: number;
    name:string;
    hasCustomDish: boolean;
    customDish?: CustomDish;
}
