import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomDishForm } from '../model/form/dish-custom.form';

export function clearCustomDishWhenTheQuantityIsZeroValidator(customDishForm: CustomDishForm): ValidatorFn {

    return (quantityDishControl: FormGroup): ValidationErrors | null => {

        if (quantityDishControl.pristine) {
            return null;
        }

        const quantityDish: number = quantityDishControl.value;

        if (Number.isInteger(quantityDish) === false) {
            return null;
        }

        if (quantityDish === 0) {
            customDishForm.reset();
        }

        return null;

    };
}
