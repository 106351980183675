import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'pedidos',
    loadChildren: () => import('./order/order.module').then((m) => m.OrderModule)
  },
  {
    path: 'crear-orden',
    loadChildren: () => import('./order-creator/order-creator.module').then(m => m.OrderCreatorModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'crear-orden',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
