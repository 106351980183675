import { FormControl, FormGroup } from '@angular/forms';

export class AdditionalDishForm extends FormGroup {

    constructor() {

        const id = new FormControl();
        const amount = new FormControl();
        const quantity = new FormControl(0);
        const name = new FormControl();

        super({ id, amount, name, quantity });
    }
}

export class AdditionalDish {
    id: number;
    amount: number;
    name: string;
    quantity: number;
}
